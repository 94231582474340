import { ApolloClientContext } from "@/contexts/apollo-client-context";
import { createApolloClient } from "@/lib/gql/createApolloClient";
import { ApolloProvider } from "@apollo/client";
import { useAccessToken } from "@nhost/nextjs";
import { useMemo } from "react";

export function ApolloClientProvider({ children }: any) {
  const accessToken = useAccessToken(); // Get token from your custom hook
  const client = useMemo(() => {
    return createApolloClient(accessToken ? accessToken : undefined);
  }, [accessToken]);

  const readonlyClient = useMemo(() => {
    return createApolloClient(
      accessToken ? accessToken : undefined,
      process.env.NEXT_PUBLIC_HASURA_URL_READONLY,
    );
  }, [accessToken]);

  return (
    <ApolloClientContext.Provider
      value={{
        client,
        readonlyClient,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApolloClientContext.Provider>
  );
}
