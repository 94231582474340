"use client";

import { NhostProvider } from "@nhost/nextjs";
import { nhost } from "../lib/nhost";
import AuthProvider from "@/providers/auth-provider";
import { PHProvider } from "@/providers/ph-provider";
import { TooltipProvider } from "@/components/ui";
import { ApolloClientProvider } from "@/providers/apollo-client-provider";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <PHProvider>
      <NhostProvider nhost={nhost}>
        <ApolloClientProvider>
          <TooltipProvider>
            <AuthProvider>{children}</AuthProvider>
          </TooltipProvider>
        </ApolloClientProvider>
      </NhostProvider>
    </PHProvider>
  );
}
