// utils/useAuth.tsx
import { useEffect } from "react";
import { useRouter } from "next/navigation";
import { useAuthenticationStatus } from "@nhost/nextjs";

const useAuth = () => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  const router = useRouter();

  useEffect(() => {
    if (isLoading) {
      return; // Skip redirection if still loading
    }

    if (!isAuthenticated) {
      router.push("/login");
    }
  }, [isAuthenticated, isLoading, router]);

  return { isAuthenticated, isLoading };
};

export default useAuth;
