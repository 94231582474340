import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"IBM_Plex_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"preload\":true,\"display\":\"swap\",\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Satoshi-Variable.woff2\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/Satoshi-VariableItalic.woff2\",\"style\":\"italic\"}],\"variable\":\"--font-sans\",\"preload\":true,\"display\":\"swap\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/workspace/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/workspace/src/components/ui/sonner.tsx");
