import useAuth from "@/hooks/use-auth";
import { AuthContext } from "@/contexts/auth-context";
import { FC, ReactNode } from "react";

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
