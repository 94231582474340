import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { RetryLink } from "@apollo/client/link/retry";
import { nanoid } from "nanoid";
import { env } from "@/lib/env";

const PUBLIC_OPERATIONS = ["SharedJobById", "SharedAnimationById"];

const isPublicQuery = (operationName: string | undefined): boolean =>
  !!operationName && PUBLIC_OPERATIONS.includes(operationName);

const createHttpLink = (uri: string): HttpLink => new HttpLink({ uri });

const createWsLink = (url: string, userToken?: string): GraphQLWsLink | null =>
  typeof window !== "undefined"
    ? new GraphQLWsLink(
        createClient({
          url,
          connectionParams: {
            headers: userToken ? { Authorization: `Bearer ${userToken}` } : {},
          },
          retryAttempts: 5,
          retryWait: (retries) =>
            new Promise((resolve) => setTimeout(resolve, retries * 1000)),
          shouldRetry: () => true,
        }),
      )
    : null;

const createTrackingLink = () =>
  setContext((operation, { headers }) => {
    return {
      headers: { ...headers, "x-sync-request-id": nanoid() },
    };
  });

const createAuthLink = (userToken?: string) =>
  setContext((operation, { headers }) => {
    if (isPublicQuery(operation.operationName)) {
      return { headers: { ...headers, "x-hasura-role": "public" } };
    }
    if (userToken || typeof window !== "undefined") {
      return { headers: { Authorization: `Bearer ${userToken}` } };
    }
    return {
      headers: {
        ...headers,
        "x-hasura-admin-secret": process.env.HASURA_ADMIN_SECRET,
      },
    };
  });

const createRetryLink = (): RetryLink =>
  new RetryLink({
    delay: { initial: 300, max: 5000, jitter: true },
    attempts: {
      max: 3,
      retryIf: (error) => error.message.includes("Network error"),
    },
  });

const createSplitLink = (
  httpLink: HttpLink,
  wsLink: GraphQLWsLink | null,
  tracingLink: ReturnType<typeof setContext>,
  authLink: ReturnType<typeof setContext>,
  retryLink: RetryLink,
) =>
  wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        retryLink.concat(tracingLink.concat(authLink.concat(wsLink))),
        retryLink.concat(tracingLink.concat(authLink.concat(httpLink))),
      )
    : retryLink.concat(tracingLink.concat(authLink.concat(httpLink)));

export function createApolloClient(
  userToken?: string,
  serverUri?: string,
): ApolloClient<NormalizedCacheObject> {
  const uri = `https://${serverUri ?? process.env.NEXT_PUBLIC_HASURA_URL}`;
  console.log(`createApolloClient - uri: ${uri}`);

  const httpLink = createHttpLink(uri);
  const wsLink = createWsLink(
    `wss://${serverUri ?? process.env.NEXT_PUBLIC_HASURA_URL}`,
    userToken,
  );
  const authLink = createAuthLink(userToken);
  const retryLink = createRetryLink();
  const tracingLink = createTrackingLink();
  const splitLink = createSplitLink(
    httpLink,
    wsLink,
    tracingLink,
    authLink,
    retryLink,
  );

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: splitLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: { fetchPolicy: "cache-and-network", errorPolicy: "all" },
      query: { fetchPolicy: "network-only", errorPolicy: "all" },
      mutate: { errorPolicy: "all" },
    },
  });
}
