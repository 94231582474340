import { createContext } from "react";
import { ApolloClient } from "@apollo/client";

export interface ApolloClientContextProps {
  client: ApolloClient<any>;
  readonlyClient: ApolloClient<any>;
}

export const ApolloClientContext = createContext<
  ApolloClientContextProps | undefined
>(undefined);
